<template>
  <not-authenticated>
    <v-card
      class="elevation-0 mx-auto"
      color="transparent">
      <h1 class="subtitle-1 mediumGray--text text-center mb-6">
        Página não encontrada
      </h1>
      <v-container fill-height>
        <v-layout
          row
          wrap
          align-center>
          <v-flex class="text-center">
            <v-btn
              depressed
              color="primary"
              @click.native="goBack">
              Voltar
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </not-authenticated>
</template>

<script>
  import NotAuthenticated from '@/components/NotAuthenticated'

  export default {
    name: 'NotFound',
    components: {
      NotAuthenticated
    },
    methods: {
      goBack () {
        // this.$router.go(-1)
        this.$router.push({ name: 'Home' })
      }
    }
  }
</script>
